import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import ArticlesCarousel from '@components/Articles/ArticlesCarousel';
import FlatExperiencesCarousel from '@components/FlatExperiencesCarousel';
import ExperienceCarousel from '@components/Experience/ExperienceGroup';
import FiltersCarousel from '@components/Filters/FiltersCarousel';
import { useKeywords } from '@app/hooks';
import { Container, EventTitle, TitleWrapper } from './styles';
import UserInteractionsGroup from '@components/UserInteractionsGroup';

const HomePage = () => {
  const {
    contentStore: {
      eventData,
      resolveInitialInteractionForGroup,
      setIsEventPage,
    },
  } = useStores();

  useEffect(() => {
    setIsEventPage(true);

    return () => setIsEventPage(false);
  }, []);

  const keywords = useKeywords(eventData?.keywords?.data || []);

  return (
    <Container min>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <TitleWrapper>
        <EventTitle>{eventData.title}</EventTitle>
      </TitleWrapper>
      {(eventData?.homePage || []).map((homePageElement: any) => {
        const keyStr = `${homePageElement.__typename}_${homePageElement.id}`;

        switch (homePageElement.__typename) {
          case 'ComponentExperienceExperienceGroup':
            return (
              <React.Fragment key={keyStr}>
                <ExperienceCarousel experiencesData={homePageElement} />
              </React.Fragment>
            );
          case 'ComponentNewsNewsGroup':
            return (
              <React.Fragment key={keyStr}>
                <ArticlesCarousel articleData={homePageElement} />
              </React.Fragment>
            );
          case 'ComponentIntersIntersGrpHome':
            return (
              <React.Fragment key={keyStr}>
                <UserInteractionsGroup
                  groupId={homePageElement.id}
                  interactions={homePageElement}
                  initialInteraction={resolveInitialInteractionForGroup(
                    homePageElement.id
                  )}
                />
              </React.Fragment>
            );
          case 'ComponentFlexpsFlExpGr':
            return (
              <React.Fragment key={keyStr}>
                <FlatExperiencesCarousel
                  flatExperiencesData={homePageElement}
                />
              </React.Fragment>
            );
          case 'ComponentFilterFiltersGroup':
            return (
              <React.Fragment key={keyStr}>
                <FiltersCarousel filtersData={homePageElement} />
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
    </Container>
  );
};

export default observer(HomePage);
