import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonOutlined from '@components/ButtonOutlined';
import { ArticleSimple } from '@app/utils/types';
import { resolveStrapiImgUrl } from '@app/utils';
import { Container, Title, DateAuthorRow, ImageBox } from './styles';
import routes from '@routes';
import { observer } from 'mobx-react-lite';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useTrackMarketoLinkClick } from '@app/hooks';
import { formatDateTo0Timezone } from '@app/utils';

const NewsItem = ({
  groupId,
  props,
  fontSize,
  isCarousel,
}: {
  groupId: string;
  props: { attributes: ArticleSimple };
  fontSize?: number;
  isCarousel?: boolean;
}) => {
  const history = useHistory();
  const eventSlug = useEventSlug();
  const trackLink = useTrackMarketoLinkClick();

  const {
    attributes: { title, date, author, image, slug, buttonText },
  } = props;

  const parsedDate = formatDateTo0Timezone(date, 'd MMM');

  const imgUrl = resolveStrapiImgUrl(image);

  const goToArticle = () => {
    trackLink();
    history.push(routes.EVENT.ARTICLE(groupId, slug, eventSlug));
  };

  return (
    <Container>
      <ImageBox image={imgUrl} {...(isCarousel && { onClick: goToArticle })} />
      <DateAuthorRow>{`${parsedDate} • ${author}`}</DateAuthorRow>
      <Title fontSize={fontSize}>{title}</Title>
      <div>
        <ButtonOutlined
          title={buttonText ? buttonText : 'Read Article'}
          onClick={goToArticle}
        />
      </div>
    </Container>
  );
};

export default observer(NewsItem);
