import { PollResult } from '@queries/getPoll';
import React from 'react';
import {
  Percentage,
  PollOptionLabel,
  PollOptionsContainer,
  Row,
} from './styles';

type Props = {
  pollResults: PollResult[];
};

const PollResultsRenderer = ({ pollResults }: Props) => {
  return (
    <PollOptionsContainer>
      {pollResults.map(({ id, option, percentage, votes }) => (
        <Row key={id}>
          <PollOptionLabel>
            {option} ({(percentage || 0).toFixed(0)}% - {votes} votes)
          </PollOptionLabel>
          <Percentage percentage={percentage} />
        </Row>
      ))}
    </PollOptionsContainer>
  );
};

export default PollResultsRenderer;
