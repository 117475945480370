import React from 'react';
import { useHistory } from 'react-router-dom';
import { resolveStrapiImgUrl } from '@app/utils';
import routes from '@routes';
import Button from '@components/Button';
import { ExperienceItem } from '@app/utils/types';
import { observer } from 'mobx-react-lite';
import {
  Container,
  Content,
  BottomWrapper,
  Title,
  Description,
  DescWrap,
} from './styles';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useTrackMarketoLinkClick } from '@app/hooks';

interface ItemProps {
  item: ExperienceItem;
  isCarousel?: boolean;
}

const BigExperienceItem = ({
  isCarousel,
  item: { slug, title, description, preExperienceBackground, carouselBtnText },
}: ItemProps) => {
  const history = useHistory();
  const eventSlug = useEventSlug();
  const trackLink = useTrackMarketoLinkClick();

  const bgImage = resolveStrapiImgUrl(preExperienceBackground);

  const handleRedirect = () => {
    trackLink();
    history.push(routes.EVENT.EXPERIENCE(slug, eventSlug));
  };

  return (
    <Container image={bgImage} {...(isCarousel && { onClick: handleRedirect })}>
      <Content>
        <Title>{title}</Title>
        <BottomWrapper>
          <DescWrap>
            <Description>{description}</Description>
          </DescWrap>
          <Button
            title={carouselBtnText}
            fontSize={14}
            onClick={handleRedirect}
          />
        </BottomWrapper>
      </Content>
    </Container>
  );
};

export default observer(BigExperienceItem);
