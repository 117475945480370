import React from 'react';
import { BookmarkDataProps } from '@app/utils/types';
import BookmarksListingItem from '@components/BookmarkListingItem';
import { BookmarkWrapper, NoBookmarksText } from './styles';

interface BookmarksListProps {
  bookmarksData: BookmarkDataProps[] | null;
  setSelectedBookmark: React.Dispatch<React.SetStateAction<any>>;
  noBookmarks: boolean;
}

const BookmarksList = ({
  bookmarksData,
  setSelectedBookmark,
  noBookmarks,
}: BookmarksListProps) => {
  return (
    <>
      {bookmarksData?.map((item, idx: number) => (
        <BookmarkWrapper
          key={`${item.data.attributes.slug}-${idx}`}
          isLast={bookmarksData.length === idx + 1}
        >
          <BookmarksListingItem
            item={item}
            setSelectedBookmark={setSelectedBookmark}
          />
        </BookmarkWrapper>
      ))}
      {noBookmarks && (
        <NoBookmarksText>You have no bookmarks yet</NoBookmarksText>
      )}
    </>
  );
};

export default BookmarksList;
