import React, { useEffect } from 'react';
import { Container, Content, QrCodeWrapper, Title } from './styles';
import defaultLpBackground from '@app/assets/landingPageDefaultBg.png';
import QRCode from 'react-qr-code';
import useMobile from '@app/hooks/useMobile';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useHistory } from 'react-router-dom';
import routes from '@routes';

const QrCodePage = () => {
  const isMobile = useMobile();
  const history = useHistory();
  const eventSlug = useEventSlug();

  useEffect(() => {
    if (isMobile) {
      history.replace(routes.EVENT.HOME(eventSlug));
    }
  }, [isMobile]);

  return (
    <Container bgImage={defaultLpBackground}>
      <Content>
        <Title>
          This website works only on mobile devices. Scan the QR code below.
        </Title>
        <QrCodeWrapper>
          <QRCode
            style={{
              height: 'auto',
              maxWidth: '100%',
              width: '100%',
              display: 'block',
            }}
            value={`${window.location.origin}${routes.EVENT.HOME(eventSlug)}`}
          />
        </QrCodeWrapper>
      </Content>
    </Container>
  );
};

export default QrCodePage;
