import React, { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { useContacts } from '@app/hooks';
import Header from '@components/Header';
import ContactItem from '@components/ContactItem';
import ContactOverlay from '@components/ContactOverlay';
import { Title } from './styles';
import useBlockScroll from '@app/hooks/useBlockScroll';
import { BasePageContainer } from '@components/BasePageContainer';
import routes from '@routes';

const ContactsPage = () => {
  const theme = useTheme();
  const { contactSlug } = useParams<{ contactSlug: string }>();

  const { contacts } = useContacts();

  const selectedContact = useMemo(
    () => contacts.find((contact) => contact?.urlSlug === contactSlug),
    [contacts, contactSlug]
  );

  useBlockScroll(!!selectedContact);

  const customCfg = {
    route: routes.CONTACTS(),
    visible: true,
    transparent: false,
    hideBurger: true,
    logoColor: theme.colors.primaryBlue,
  };

  return (
    <>
      <Header customCfg={customCfg} />
      <BasePageContainer min>
        <Title>Nokia Contacts</Title>
        {contacts.length > 0 ? (
          <>
            {contacts.map((contact, idx: number) => (
              <React.Fragment key={contact.email}>
                <ContactItem {...contact} isFirst={idx === 0} />
              </React.Fragment>
            ))}
          </>
        ) : null}
        {selectedContact && <ContactOverlay {...selectedContact} />}
      </BasePageContainer>
    </>
  );
};

export default ContactsPage;
