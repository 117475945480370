import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { BasePageContainer } from '@components/BasePageContainer';
import { Title, SendBtnWrapper } from './styles';
import BookmarkOverlay from '@components/BookmarkOverlay';
import Button from '@components/Button';
import BookmarksList from '@components/BookmarksList';
import SendMailOverlay from '@components/SendMailOverlay';

const BookmarksPage = () => {
  const {
    contentStore: { eventData },
    bookmarksStore: { bookmarksData },
  } = useStores();

  const [selectedBookmark, setSelectedBookmark] = useState();
  const [sendMailOverlayOpen, setSendMailOverlayOpen] = useState(
    bookmarksData?.length === 0 || eventData?.disableBookmarkEmails
      ? false
      : true
  );

  const noBookmarks = bookmarksData?.length === 0;

  return (
    <BasePageContainer min>
      <Title>Bookmarks</Title>
      <BookmarksList
        bookmarksData={bookmarksData}
        setSelectedBookmark={setSelectedBookmark}
        noBookmarks={noBookmarks}
      />
      {!noBookmarks && !eventData?.disableBookmarkEmails && (
        <SendBtnWrapper>
          <Button
            title="Send all bookmarks to my email"
            fontSize={14}
            styles="width: 100%; max-width: 450px; padding: 12px 30px;"
            onClick={() => setSendMailOverlayOpen(true)}
          />
        </SendBtnWrapper>
      )}
      {selectedBookmark && (
        <BookmarkOverlay
          selectedBookmark={selectedBookmark}
          setSelectedBookmark={setSelectedBookmark}
        />
      )}
      {sendMailOverlayOpen && !eventData?.disableBookmarkEmails && (
        <SendMailOverlay setSendMailOverlayOpen={setSendMailOverlayOpen} />
      )}
    </BasePageContainer>
  );
};

export default observer(BookmarksPage);
