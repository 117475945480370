import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@emotion/react';
import { ApolloProvider } from '@apollo/client';
import routes from '@routes';
import history from '@app/history';
import Page from '@components/Page';
import theme from '@app/theme';
import NotFoundPage from '@pages/notFoundPage';
import ContactsPage from '@pages/contactsPage';
import CookiesPage from '@pages/cookiesPage';
import CookiesListPage from '@pages/cookiesListPage';
import Event from '@components/Event';
import { apolloClient } from '@app/api';
import ScrollTop from '@components/ScrollTop';
import { REDIRECT_URL_STORAGE_KEY } from '@constants';

const App = () => {
  useEffect(() => {
    return () => {
      localStorage.removeItem(REDIRECT_URL_STORAGE_KEY);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="Keywords" content="" />
      </Helmet>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <ScrollTop />
            <Page>
              <Switch>
                <Route
                  exact
                  path={routes.CONTACTS()}
                  component={ContactsPage}
                />
                <Route exact path={routes.COOKIES} component={CookiesPage} />
                <Route
                  exact
                  path={routes.COOKIES_LIST}
                  component={CookiesListPage}
                />

                <Route exact path={routes.NOT_FOUND()}>
                  <NotFoundPage />
                </Route>

                <Route path={routes.EVENT.HOME()}>
                  <Event />
                </Route>

                <Route>
                  <NotFoundPage />
                </Route>
              </Switch>
            </Page>
          </Router>
          <ToastContainer
            position="top-right"
            autoClose={3500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="colored"
          />
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default observer(App);
