import routes from '@routes';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEventSlug } from './useEventSlug';
import useMobile from './useMobile';

function useDesktopGuard() {
  const isMobile = useMobile();
  const history = useHistory();
  const location = useLocation();
  const eventSlug = useEventSlug();

  const desktopAvailablePages: string[] = ['poll-results'];

  useEffect(() => {
    if (
      !isMobile &&
      !desktopAvailablePages.some((v) => location.pathname.includes(v))
    ) {
      history.replace(routes.EVENT.QR_CODE(eventSlug));
    }
  }, [location.pathname]);
}

export default useDesktopGuard;
