import { gql } from '@apollo/client';

export default gql`
  query GetEventBySlug($slug: String! = "") {
    events(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          title
          slug
          eventStartDate
          eventEndDate
          disableBookmarkEmails
          authType
          marketoBaseUrl
          marketoMunchkinId
          marketoFormId
          keywords(pagination: { limit: -1 }) {
            data {
              attributes {
                keyword
              }
            }
          }
          schedule {
            data {
              attributes {
                slug
                scheduleItem(pagination: { limit: -1 }) {
                  id
                  title
                  startTime
                  endTime
                  slug
                  description_ck
                  image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  presenters {
                    name
                    title
                    company
                    linkedinUrl
                    twitterUrl
                    email
                  }
                }
                keywords(pagination: { limit: -1 }) {
                  data {
                    attributes {
                      keyword
                    }
                  }
                }
              }
            }
          }
          navigation {
            data {
              attributes {
                navItems(pagination: { limit: -1 }) {
                  title
                  url
                  target
                }
              }
            }
          }
          socials {
            facebookUrl
            instagramUrl
            twitterUrl
            nokiaUrl
          }
          signUpIntro {
            title
            description
            keywords(pagination: { limit: -1 }) {
              data {
                attributes {
                  keyword
                }
              }
            }
          }
          landingPageContent {
            backgroundImage {
              data {
                attributes {
                  url
                }
              }
            }
            btnText
            calendarBtnText
            keywords(pagination: { limit: -1 }) {
              data {
                attributes {
                  keyword
                }
              }
            }
          }
          homePage {
            __typename
            ... on ComponentExperienceExperienceGroup {
              id
              experience {
                data {
                  attributes {
                    keywords(pagination: { limit: -1 }) {
                      data {
                        attributes {
                          keyword
                        }
                      }
                    }
                    experience_items(
                      pagination: { limit: -1 }
                      filters: { publishedAt: { not: null } }
                    ) {
                      data {
                        id
                        attributes {
                          slug
                          title
                          description
                          hidden
                          hideExitButton
                          defaultExperience
                          disableBookmarking
                          experienceUrl
                          isExternalLink
                          keywords(pagination: { limit: -1 }) {
                            data {
                              attributes {
                                keyword
                              }
                            }
                          }
                          document {
                            data {
                              attributes {
                                url
                                ext
                              }
                            }
                          }
                          isDocumentDownload
                          btnText
                          carouselBtnText
                          preExperienceBackground {
                            data {
                              attributes {
                                url
                              }
                            }
                          }
                          order
                          interactions {
                            userInteraction {
                              data {
                                attributes {
                                  interactions {
                                    __typename
                                    ... on ComponentIntersPollInteraction {
                                      poll {
                                        data {
                                          id
                                          attributes {
                                            hideOnResultsPage
                                          }
                                        }
                                      }
                                    }
                                    ... on ComponentIntersRatingInteraction {
                                      rating {
                                        data {
                                          id
                                          attributes {
                                            hideOnResultsPage
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentNewsNewsGroup {
              id
              news_groups(
                pagination: { limit: -1 }
                filters: { publishedAt: { not: null } }
              ) {
                data {
                  id
                  attributes {
                    slug
                    homeCarouselTitle
                    keywords(pagination: { limit: -1 }) {
                      data {
                        attributes {
                          keyword
                        }
                      }
                    }
                    news_items(pagination: { limit: -1 }) {
                      data {
                        attributes {
                          slug
                          title
                          date
                          author
                          buttonText
                          image {
                            data {
                              attributes {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentFilterFiltersGroup {
              id
              filter_groups(
                pagination: { limit: -1 }
                filters: { publishedAt: { not: null } }
              ) {
                data {
                  id
                  attributes {
                    homeCarouselTitle
                    keywords(pagination: { limit: -1 }) {
                      data {
                        attributes {
                          keyword
                        }
                      }
                    }
                    filterItems(pagination: { limit: -1 }) {
                      id
                      slug
                      title
                      iframeLink
                      backgroundImage {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                      circleImage {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                      keywords(pagination: { limit: -1 }) {
                        data {
                          attributes {
                            keyword
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentIntersIntersGrpHome {
              id
              user_interactions(
                pagination: { limit: -1 }
                filters: { publishedAt: { not: null } }
              ) {
                data {
                  attributes {
                    interactions {
                      __typename
                      ... on ComponentIntersPollInteraction {
                        poll {
                          data {
                            id
                            attributes {
                              hideOnResultsPage
                            }
                          }
                        }
                      }
                      ... on ComponentIntersRatingInteraction {
                        rating {
                          data {
                            id
                            attributes {
                              hideOnResultsPage
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentFlexpsFlExpGr {
              id
              flat_experience_groups(pagination: { limit: -1 }) {
                data {
                  id
                  attributes {
                    title
                    slug
                    homeCarouselTitle
                    keywords(pagination: { limit: -1 }) {
                      data {
                        attributes {
                          keyword
                        }
                      }
                    }
                    flat_experiences(pagination: { limit: -1 }) {
                      data {
                        attributes {
                          slug
                          buttonText
                          title
                          disableBookmarking
                          image {
                            data {
                              attributes {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
