import routes from '@routes';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useStores } from '@stores';
import AuthCheck from '@components/AuthCheck';
import ContentHandler from '@components/ContentHandler';
import LandingPage from '@pages/landingPage';
import ExperiencePage from '@pages/experiencePage';
import ExperiencesPage from '@pages/experiencesPage';
import HomePage from '@pages/homePage';
import ArticlePage from '@pages/articlePage';
import ArticlesPage from '@pages/articlesPage';
import SomethingWentWrongPage from '@pages/somethingWrongPage';
import RatingPage from '@pages/ratingPage';
import PollPage from '@pages/pollPage';
import PollResultsPage from '@pages/pollResultsPage';
import FiltersPage from '@pages/filtersPage';
import FilterPage from '@pages/filterPage';
import FlatExperiencePage from '@pages/flatExperiencePage';
import FlatExperiencesPage from '@pages/flatExperiencesPage';
import ExperienceVisitPage from '@pages/experienceVisitPage';
import SchedulePage from '@pages/schedulePage';
import BookmarksPage from '@pages/bookmarksPage';
import EventLoaderPlaceholder from '@components/EventLoaderPlaceholder';
import useIsEventLoading from '@app/hooks/useIsEventLoading';
import Header from '@components/Header';
import { observer } from 'mobx-react-lite';
import QrCodePage from '@pages/qrCodePage';
import useDesktopGuard from '@app/hooks/useDesktopGuard';
import { useEventSlug } from '@app/hooks/useEventSlug';
import SignUpPage from '@pages/signUpPage';

const Event = () => {
  const {
    contentStore: { eventData },
    bookmarksStore: { eventSlug: storeEventSlug, setEventSlug },
  } = useStores();
  const isLoading = useIsEventLoading();
  const eventSlug = useEventSlug();
  useDesktopGuard();

  useEffect(() => {
    if (storeEventSlug !== eventSlug) {
      setEventSlug(eventSlug);
    }
  }, [eventSlug]);

  return (
    <>
      <ContentHandler />
      {eventData && <AuthCheck />}

      {!isLoading && <Header />}
      <Switch>
        {isLoading && <EventLoaderPlaceholder />}
        <Route exact path={routes.EVENT.HOME()} component={HomePage} />
        <Route
          exact
          path={routes.EVENT.LANDING_PAGE()}
          component={LandingPage}
        />
        <Route exact path={routes.EVENT.SIGN_UP()} component={SignUpPage} />
        <Route
          exact
          path={routes.EVENT.EXPERIENCES()}
          component={ExperiencesPage}
        />
        <Route
          exact
          path={routes.EVENT.EXPERIENCE()}
          component={ExperiencePage}
        />
        <Route
          exact
          path={routes.EVENT.EXPERIENCE_VISIT()}
          component={ExperienceVisitPage}
        />
        <Route exact path={routes.EVENT.ARTICLES()} component={ArticlesPage} />
        <Route exact path={routes.EVENT.ARTICLE()} component={ArticlePage} />
        <Route
          exact
          path={routes.EVENT.FLAT_EXPERIENCES()}
          component={FlatExperiencesPage}
        />
        <Route
          exact
          path={routes.EVENT.FLAT_EXPERIENCE()}
          component={FlatExperiencePage}
        />
        <Route
          exact
          path={routes.EVENT.BOOKMARKS()}
          component={BookmarksPage}
        />
        <Route exact path={routes.EVENT.FILTERS()} component={FiltersPage} />
        <Route exact path={routes.EVENT.FILTER()} component={FilterPage} />
        <Route exact path={routes.EVENT.SCHEDULE()} component={SchedulePage} />
        <Route exact path={routes.EVENT.RATING()} component={RatingPage} />
        <Route exact path={routes.EVENT.POLL()} component={PollPage} />
        <Route
          exact
          path={routes.EVENT.POLL_RESULTS()}
          component={PollResultsPage}
        />
        <Route exact path={routes.EVENT.QR_CODE()} component={QrCodePage} />
        <Route
          exact
          path={routes.EVENT.SOMETHING_WRONG()}
          component={SomethingWentWrongPage}
        />
        <Redirect to={`${routes.NOT_FOUND()}?ev=${eventSlug}`} push={false} />
      </Switch>
    </>
  );
};

export default observer(Event);
