import styled from '@emotion/styled';

export const CloseBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 28px;
  top: 28px;
  color: #49535f;
  z-index: 1;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.blueBlack};
  font-size: 30px;
  line-height: 36px;
  font-family: NokiaPureHeadline-Regular;
  align-self: flex-start;
  text-align: start;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
  margin-bottom: auto;
`;

interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

export const ImageContainer = styled.div<ImageBoxProps>`
  position: relative;
  height: 276px;
  min-height: 276px;
  width: 100vw;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;

export const Description = styled.div`
  font-family: NokiaPureText-Regular;
  font-size: 16px;
  line-height: 32px;
  margin-top: 14px;
  width: 100%;
`;

export const TimesRow = styled.div`
  display: flex;
  width: 100%;
`;

export const BtnWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
`;

export const AuthorDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const AuthorCompanyDetail = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  font-family: 'NokiaPureHeadline-Regular';
  font-size: 16px;
`;

export const ReachMeText = styled(AuthorCompanyDetail)`
  font-family: 'NokiaPureHeadline-Bold';
`;

export const SocialsRow = styled.div`
  display: flex;
  gap: 11px;
`;

export const SocialItem = styled.a`
  padding: 11px;
  border-radius: 2px;
  width: 44px;
  height: 44px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fff;
  text-decoration: none;
`;
