import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import ImageCarousel from '@components/ImageCarousel';
import BookmarkLinks from '@components/BookmarkLinks';
import { BaseContainer } from '@components/BaseContainer';
import { useStores } from '@stores';
import { resolveStrapiImgUrl, convertOembedToIframe } from '@app/utils';
import { useHidableNavElement } from '@app/hooks/useHidableNav';
import { useFlatExperience, useKeywords } from '@app/hooks';
import CkContent from '@components/CkContent';
import {
  ImageContainer,
  Content,
  Title,
  FlatExperienceContent,
} from './styles';

const FlatExperiencePage = () => {
  const [bookmarkSaved, setBookmarkSaved] = useState(false);
  const [imgRef, setImgRef] = useState<HTMLDivElement | null>(null);

  useHidableNavElement(imgRef, { rootMargin: '-75px 0px 0px 0px' });
  const {
    contentStore: { eventData },
    bookmarksStore: { setBookmark },
  } = useStores();

  const { loading, flatExperienceData, groupSlug, eventSlug, groupId } =
    useFlatExperience(eventData);

  useEffect(() => {
    if (flatExperienceData && groupSlug && eventSlug && !bookmarkSaved) {
      if (!flatExperienceData.disableBookmarking) {
        setBookmark({
          flatExperience: {
            slug: flatExperienceData?.slug,
            groupSlug,
            eventSlug,
            groupId,
          },
          type: 'flatExperience',
        });
      }

      setBookmarkSaved(true);
    }
  }, [flatExperienceData, groupSlug, eventSlug, bookmarkSaved]);

  const bgImage = resolveStrapiImgUrl(flatExperienceData?.image);

  const keywords = useKeywords(flatExperienceData?.keywords?.data || []);

  const { carouselPosition, carouselItems } = useMemo(
    () => ({
      carouselPosition: flatExperienceData?.carouselPosition,
      carouselItems:
        flatExperienceData?.image_carousel?.data?.attributes?.carouselItems,
    }),
    [flatExperienceData]
  );

  if (loading || !flatExperienceData) return null;

  return (
    <BaseContainer min>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <ImageContainer ref={setImgRef} image={bgImage} />
      <Content>
        <Title>{flatExperienceData?.title}</Title>
        {carouselPosition === 'top' && <ImageCarousel images={carouselItems} />}
        <FlatExperienceContent>
          <CkContent
            text={convertOembedToIframe(flatExperienceData?.description_ck!)}
          />
        </FlatExperienceContent>
        {carouselPosition === 'bottom' && (
          <ImageCarousel images={carouselItems} />
        )}
        <BookmarkLinks bookmarkLinks={flatExperienceData?.bookmarkLinks} />
      </Content>
    </BaseContainer>
  );
};

export default observer(FlatExperiencePage);
