import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useFlatExperiencesPage } from '@app/hooks';
import { BasePageContainer } from '@components/BasePageContainer';
import ListingItem from '@components/FlatExperience/ListingItem';
import { Title, FlatExperienceWrapper } from './styles';

const FlatExperiencesPage = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { flatExperiencesListingData, keywords } = useFlatExperiencesPage(
    eventData
  );

  if ((flatExperiencesListingData || []).length === 0) return null;

  return (
    <BasePageContainer min>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Title>Supporting Content</Title>
      {flatExperiencesListingData.map((elem, idx: number) => (
        <FlatExperienceWrapper
          key={`${elem.item.attributes.slug}-${idx}`}
          isLast={flatExperiencesListingData.length === idx + 1}
        >
          <ListingItem props={elem.item.attributes} groupId={elem.groupId} />
        </FlatExperienceWrapper>
      ))}
    </BasePageContainer>
  );
};

export default observer(FlatExperiencesPage);
