import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonOutlined from '@components/ButtonOutlined';
import { resolveStrapiImgUrl } from '@app/utils';
import { Container, Title, ImageBox } from './styles';
import routes from '@routes';
import { observer } from 'mobx-react-lite';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useTrackMarketoLinkClick } from '@app/hooks';
import { FlatExperience } from '@app/utils/types';

const FlatExperienceItem = ({
  groupId,
  props,
  fontSize,
  isCarousel,
}: {
  groupId: string;
  props: { attributes: FlatExperience };
  fontSize?: number;
  isCarousel?: boolean;
}) => {
  const history = useHistory();
  const eventSlug = useEventSlug();
  const trackLink = useTrackMarketoLinkClick();

  const {
    attributes: { title, image, slug, buttonText },
  } = props;

  const imgUrl = resolveStrapiImgUrl(image);

  const goToFlatExperience = () => {
    trackLink();
    history.push(routes.EVENT.FLAT_EXPERIENCE(eventSlug, slug, groupId));
  };

  return (
    <Container>
      <ImageBox
        image={imgUrl}
        {...(isCarousel && { onClick: goToFlatExperience })}
      />
      <Title fontSize={fontSize}>{title}</Title>
      <div>
        <ButtonOutlined
          title={buttonText ? buttonText : 'Read Article'}
          onClick={goToFlatExperience}
          styles="min-width: 131px;"
        />
      </div>
    </Container>
  );
};

export default observer(FlatExperienceItem);
