import styled from '@emotion/styled';

export const Card = styled.div<{
  pulse?: boolean;
  border?: boolean;
  lessPadding?: boolean;
  enableMinHeight?: boolean;
  justifyValue?: string;
}>`
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  ${({ enableMinHeight }) => enableMinHeight && 'min-height: 370px;'}
  width: 100%;
  position: relative;
  justify-content: ${({ justifyValue }) =>
    justifyValue ? justifyValue : 'center'};
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding: ${({ lessPadding }) => (lessPadding ? '20px 30px' : '30px 40px')};
  ${({ border, theme }) =>
    border && `border: 1px solid ${theme.colors.grey300};`}

  ${({ pulse }) =>
    pulse &&
    `
    animation: pulse 1.5s ease-in-out infinite;
  `}

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const CloseButtonContainer = styled.div<{ lessPadding?: boolean }>`
  position: absolute;
  top: ${({ lessPadding }) => (lessPadding ? '10px' : '20px')};
  right: ${({ lessPadding }) => (lessPadding ? '10px' : '20px')};
`;

export const Title = styled.h1`
  font-family: NokiaPureText-Bold;
  font-size: 20px;
  text-align: center;
  margin: 0 0 10px 0;
  color: ${({ theme }) => theme.colors.blueBlack};
`;

export const Description = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.grey700};
  line-height: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  width: min(100%, 146px);
  align-self: center;

  & > button {
    width: 100%;
    text-align: center;
    padding-inline: 0;
  }
`;

export const StarsContainer = styled.div`
  padding: 35px 0;
  display: flex;
  gap: 10px;
`;

export const PollOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

export const Error = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
  color: red;
`;

export const Percentage = styled.div<{ percentage: number }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey300};
  border-radius: 2px;
  height: 40px;

  &:after {
    content: '';
    display: block;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: width 0.5s ease-in-out;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    width: ${({ percentage }) => percentage}%;
  }
`;

export const PollOptionLabel = styled.p`
  font-size: 14px;
  text-align: left;
  margin: 0;
  margin: 0 0 5px 0;
  color: ${({ theme }) => theme.colors.grey700};
`;
