import routes from '@routes';
import theme from '@app/theme';
import { HeaderConfig } from '@app/utils/types';

export const USER_STORAGE_KEY: string = 'NOKIA_EXPERIENCE_USER';

export const USER_BOOKMARKS_STORAGE_KEY: string = 'NOKIA_USER_BOOKMARKS';
export const BOOKMARKS_STORAGE_VERSION = '0.3';

export const REDIRECT_URL_STORAGE_KEY: string = 'SIGN_UP_REDIRECT_PATH';

export const NON_REDIRECT_ROUTES: string[] = [
  routes.EVENT.SIGN_UP(),
  routes.EVENT.LANDING_PAGE(),
  routes.NOT_FOUND(),
  routes.CONTACTS(),
  routes.COOKIES,
  routes.COOKIES_LIST,
];

export const SIGN_UP_REDIRECT_AUTH_TYPES: string[] = [
  'Basic_password',
  'Marketo_form',
];

interface HeaderConfigItems extends Array<HeaderConfig> {}

export const HEADER_CONFIG: HeaderConfigItems = [
  { route: 'default', visible: false },
  {
    route: routes.NOT_FOUND(),
    visible: true,
    logoColor: '#fff',
    transparent: true,
    logoOnly: true,
  },
  {
    route: routes.EVENT.QR_CODE(),
    visible: true,
    logoColor: '#fff',
    transparent: true,
    logoOnly: true,
  },
  {
    route: routes.EVENT.LANDING_PAGE(),
    visible: false,
  },
  { route: routes.EVENT.SIGN_UP(), visible: false },
  {
    route: routes.EVENT.EXPERIENCE(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: true,
  },
  {
    route: routes.EVENT.EXPERIENCE_VISIT(),
    visible: false,
    logoColor: '#fff',
    transparent: true,
  },
  {
    route: routes.EVENT.EXPERIENCES(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: false,
  },
  {
    route: routes.EVENT.HOME(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: false,
    isHomepage: true,
  },
  {
    route: routes.EVENT.ARTICLE(),
    visible: true,
    logoColor: '#fff',
    transparent: true,
  },
  {
    route: routes.EVENT.ARTICLES(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: false,
  },
  {
    route: routes.EVENT.FLAT_EXPERIENCE(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: true,
  },
  {
    route: routes.EVENT.FLAT_EXPERIENCES(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: false,
  },
  {
    route: routes.EVENT.BOOKMARKS(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: false,
  },
  {
    route: routes.EVENT.SOMETHING_WRONG(),
    visible: true,
    logoColor: '#fff',
    transparent: true,
    logoOnly: true,
  },
  {
    route: routes.EVENT.RATING(),
    visible: true,
    logoOnly: true,
    transparent: true,
  },
  {
    route: routes.EVENT.POLL(),
    visible: true,
    logoOnly: true,
    transparent: true,
  },
  {
    route: routes.EVENT.FILTERS(),
    visible: true,
    logoColor: theme.colors.primaryBlue,
    transparent: false,
  },
  {
    route: routes.EVENT.FILTER(),
    visible: true,
    logoColor: '#fff',
    transparent: true,
  },
  {
    route: routes.EVENT.SCHEDULE(),
    visible: true,
    transparent: false,
    logoColor: theme.colors.primaryBlue,
  },
  {
    route: routes.CONTACTS(),
    visible: true,
    transparent: false,
    hideBurger: true,
    logoColor: theme.colors.primaryBlue,
  },
];

export const MONTHS: { value: number | undefined; label: string }[] = [
  { value: undefined, label: 'Show all' },
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];
