import { BaseContainer } from '@components/BaseContainer';
import styled from '@emotion/styled';

export const Container = styled(BaseContainer)`
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

export const Title = styled.div`
  color: #fff;
  font-size: 32px;
  max-width: 512px;
  text-align: center;
  font-family: NokiaPureHeadline-Bold;
`;

export const QrCodeWrapper = styled.div`
  max-width: 256px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
`;
