import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useFiltersPage } from '@app/hooks';
import FilterItem from '@components/Filters/FilterItem';
import { Title, FiltersWrapper, FilterItemWrapper } from './styles';
import { BasePageContainer } from '@components/BasePageContainer';

const FiltersPage = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { filtersContent, keywords } = useFiltersPage(eventData);

  return (
    <BasePageContainer min>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Title>Photo Filters</Title>

      <FiltersWrapper>
        {filtersContent.map((group: any, index: number) => (
          <React.Fragment key={index}>
            {group.map((filter: any) => (
              <FilterItemWrapper key={filter.id}>
                <FilterItem
                  filter={filter}
                  imgHeight={276}
                  circleSize={75}
                  marginBottom={40}
                />
              </FilterItemWrapper>
            ))}
          </React.Fragment>
        ))}
      </FiltersWrapper>
    </BasePageContainer>
  );
};

export default observer(FiltersPage);
