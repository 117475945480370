import React from 'react';
import Button from '@components/Button';
import { downloadExperienceDocument } from '@app/utils';
import { Container, Title, Description } from './styles';
import { useHistory } from 'react-router-dom';
import routes from '@routes';
import { ExperienceItem } from '@app/utils/types';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { Mode, useUserInteraction } from '@app/hooks/useUserInteraction';
import { useTrackMarketoLinkClick } from '@app/hooks';

type Props = {
  experience: ExperienceItem;
};

const ExperienceIntro = ({ experience }: Props) => {
  const {
    title,
    slug,
    description,
    btnText,
    document,
    interactions,
    isExternalLink,
    experienceUrl,
    isDocumentDownload,
  } = experience;
  const eventSlug = useEventSlug();
  const history = useHistory();
  const trackLink = useTrackMarketoLinkClick();

  const { interactWithUser } = useUserInteraction();

  const mode: Mode = {
    mode: 'routing',
    experienceSlug: slug,
    routingType: 'push',
  };

  const handleExit = () => {
    interactWithUser(mode, interactions, null, {
      noInteractionFallback: () =>
        history.push(routes.EVENT.EXPERIENCES(eventSlug)),
    });
  };

  const handleClick = () => {
    trackLink();
    if (isDocumentDownload) {
      downloadExperienceDocument({ title, file: document });
      handleExit();
    } else if (isExternalLink) {
      window.open(experienceUrl, '_blank');
      handleExit();
    } else {
      history.push(routes.EVENT.EXPERIENCE_VISIT(experience.slug, eventSlug));
    }
  };

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button
        title={btnText || 'Enter experience'}
        fontSize={14}
        onClick={handleClick}
      />
    </Container>
  );
};

export default ExperienceIntro;
