import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useExperience } from '@app/hooks';
import { resolveStrapiImgUrl } from '@app/utils';
import DefaultExperienceBg from '@app/assets/experienceDefaultBg.png';
import ExperienceIntro from '@components/Experience/ExperienceIntro';
import { BaseContainer } from '@components/BaseContainer';
import { Mode, useUserInteraction } from '@app/hooks/useUserInteraction';
import { useEventSlug } from '@app/hooks/useEventSlug';
import routes from '@routes';

const ExperiencePage = () => {
  const [bookmarkSaved, setBookmarkSaved] = useState(false);

  const eventSlug = useEventSlug();
  const history = useHistory();
  const {
    contentStore: { eventData },
    bookmarksStore: { setBookmark },
  } = useStores();
  const { interactWithUser } = useUserInteraction();
  const { experienceData, keywords } = useExperience(eventData);

  const mode: Mode = {
    mode: 'routing',
    experienceSlug: experienceData?.slug,
    routingType: 'push',
  };

  useEffect(() => {
    if (experienceData && eventSlug && !bookmarkSaved) {
      if (!experienceData.disableBookmarking) {
        setBookmark({
          experience: { slug: experienceData?.slug, eventSlug },
          type: 'experience',
        });
      }

      setBookmarkSaved(true);
    }
  }, [experienceData, eventSlug, bookmarkSaved]);

  useEffect(() => {
    if (experienceData?.hidden) {
      if (experienceData?.isExternalLink) {
        window.open(experienceData?.experienceUrl, '_blank');
        interactWithUser(mode, experienceData?.interactions, null, {
          noInteractionFallback: () =>
            history.push(routes.EVENT.EXPERIENCES(eventSlug)),
        });
      } else {
        history.push(
          routes.EVENT.EXPERIENCE_VISIT(experienceData.slug, eventSlug)
        );
      }
    }
  }, []);

  const bgImage = resolveStrapiImgUrl(experienceData?.preExperienceBackground);

  return (
    <BaseContainer min bgImage={bgImage || DefaultExperienceBg}>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <ExperienceIntro experience={experienceData} />
    </BaseContainer>
  );
};

export default observer(ExperiencePage);
