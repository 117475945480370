import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { BaseContainer } from '@components/BaseContainer';
import PollResultsBg from '@app/assets/pollsPageBg.png';
import NokiaLogo from '@app/assets/nokiaLogo';
import PollResultsListing from '@components/PollResultsListing';
import { Title, Container } from './styles';

const PollResultsPage = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { title } = eventData || {};

  return (
    <BaseContainer min bgImage={PollResultsBg}>
      <Container>
        <NokiaLogo fill="#fff" />
        <Title>{title}</Title>
        <PollResultsListing />
      </Container>
    </BaseContainer>
  );
};

export default observer(PollResultsPage);
