import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  gap: 32px;
  margin-bottom: 32px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 400;
  font-family: 'NokiaPureText-Regular';
  color: #fff;
`;
