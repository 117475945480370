import styled from '@emotion/styled';

interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

export const ImageContainer = styled.div<ImageBoxProps>`
  height: 50vh;
  width: 100vw;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;

export const Content = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.div`
  font-family: NokiaPureHeadline-Light;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.grey900};
  line-height: 36px;
`;

export const FlatExperienceContent = styled.div`
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.grey900};
`;
